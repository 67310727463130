.clickable {
  text-decoration: underline;
  cursor: pointer;
}

.paragraph {
  line-height: 2.625em;
  font-size: 1.25rem;
}

.text-bold-blue, .text-bold-blue-2,
.text-bold-gray, .text-bold-gray-2 {
  font-weight: 700;
}

.text-bold-blue {
  color: #327ec7;
}

.text-bold-blue-2 {
  color: #3697c3;
}

.text-bold-gray {
  color: #474448;
}

.text-bold-gray-2 {
  color: #c6c6c6;
}

@media only screen and (max-width: 800px) {
  .paragraph {
    padding-top: 1rem;
    line-height: 2.25em;
    font-size: 1.125rem;
  }
}
