.wave-container {
  height: 10rem;
  width: 100%;
}

.waves {
  position: relative;
  height: 100%;

  .wave {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .path {
      stroke: none;
    }
  }
}

@media only screen and (max-width: 800px) {
  .wave-container {
    height: 5rem;
  }
}
