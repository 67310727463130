@use '@carbon/react' as *;

.enigma-document {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.125rem solid #000;
  outline: 0.0625rem solid #FFF;
  background-color: #FFF;
  color: #000;
  fill: #000;
  stroke: none;

  h1 {
    font-weight: 700;
    text-align: center;
  }

  svg {
    padding-top: 2rem;
    width: 60%;
  }
}

.flexed-row {
  display: flex;
  flex-direction: row;
  column-gap: 4rem;
  row-gap: 4rem;

  .even {
    flex: 1 1 0rem;
  }
}

.titled-body {
  .link {
    color: $link-primary !important;

    &:hover {
      color: $link-primary-hover !important;
    }
  }

  img {
    padding-top: 4rem;
  }
}

.titled-divided-image-body {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  width: 100%;

  .sections {
    flex-basis: 60%;
  }

  .image-container {
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    flex-basis: 40%;
    
    img {
      width: 100%;
    }
  }
}

.titled-image-body {
  .wrapped-body {
    padding-top: 1rem;
    text-align: justify;
  
    img {
      width: 40%;
    }

    img ~ img {
      padding-top: 5rem;
    }

    &.left-image {
      img {
        float: left;
        margin-right: 2rem;
      }
    }

    &.right-image {
      img {
        float: right;
        margin-left: 2rem;
      }
    }
  }

  .double-image {
    display: block;
  }
}

.titled-list {
  .list {
    margin: 2rem 1rem;
    padding: 1rem;
    background-color: #327ec7;

    .list-item {
      padding: 1rem;
    }

    .list-item:not(:last-of-type) {
      padding-bottom: 1rem;
    }
  }

  .no-list, .ordered, .unordered {
    li {
      list-style-position: outside;
    }
  }

  .no-list {
    li {
      list-style: none;
    }
  }

  .ordered {
    li {
      list-style: list-item;
    }
  }

  .unordered {
    li {
      list-style: disc;
    }
  }
}

.header {
  padding-bottom: 2rem;
}

.header-3, .header-4 {
  padding-bottom: 1rem;
}

.image-wrapper {
  padding-top: 2rem;

  img {
    width: 100%;
  }
}

.paragraph {
  font-size: 1.125rem;
  line-height: 2.125rem;
  font-weight: 500;
  white-space: pre-line;
  text-align: justify;
}

.section-wrapper {
  padding: 4rem 2rem;

  .text-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    white-space: pre-line;
  }

  &.flex {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    padding-top: 2rem;
    padding-bottom: 5rem;
  }
}

.section-wrapper:nth-of-type(odd) {
  background-color: $background-inverse;
  color: $text-inverse;

  .titled-list {
    .list {
      background-color: $layer-01;
      color: $text-on-color;
    }
  }
}

.subsection {
  padding-top: 2rem;

  .header-3 {
    color: #3bafbf;
  }
}

@media only screen and (max-width: 800px) {
  .flexed-row {
    flex-direction: column;
  }

  .titled-divided-image-body {
    flex-direction: column;

    .image-container {
      padding: 2rem 0;
      width: 100%;
      
      img {
        width: 100%;
      }
    }
  }

  .titled-image-body {
    .wrapped-body {
      &.left-image {
        img {
          float: none;
          width: 100%;
          overflow: hidden;
          margin: 0;
          padding-bottom: 1rem;
        }
      }
      
      &.right-image {
        img {
          float: none;
          width: 100%;
          overflow: hidden;
          margin: 0;
          padding-bottom: 1rem;
        }
      }
    }
  }

  .titled-list {
    .list {
      margin: 1rem 0.5rem;
    }
  }

  .section-wrapper {
    padding: 4rem 1rem;

    &.flex {
      display: flex;
      flex-direction: column;
      row-gap: 3rem;
      padding: 2rem 0.5rem 5rem 0.5rem;
    }

    .text-section {
      padding: 0;
    }
  }

  .header, .header-3, .header-4 {
    text-align: center;
  }

  .header {
    font-size: 2rem;
  }

  .header-3 {
    font-size: 1.5rem;
  }

  .paragraph {
    text-align: start;
  }
}
