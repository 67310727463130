@use '@carbon/react' as *;

.home {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-image: url('../../../public/images/fire-with-couple.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow-y: scroll;

  .card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 5rem;
    row-gap: 5rem;
    padding: 9rem 4rem 7rem 4rem;
    flex-grow: 1;

    .centered-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card {
      display: flex;
      max-height: 30rem;
      aspect-ratio: 1 / 1.25;
      overflow: hidden;
    }
  }

  .statement {
    margin: 0 4rem 4rem 4rem;
    padding: 4rem;
    background-color: rgba(black, 0.5);
    white-space: pre-line;
  }
}

.paragraph {
  line-height: 2.625em;
  font-size: 1.25rem;
}

@media only screen and (max-width: 800px) {
  .home {
    height: auto;
    background-attachment: fixed;

    .card-container {
      flex-direction: column;
      align-items: center;

      .card {
        max-height: 15rem;
      }
    }

    .statement {
      margin: 0.5rem;
      padding: 2rem 1rem;
    }
  }

  .paragraph {
    line-height: 2.25em;
    font-size: 1.125rem;
  }
}
