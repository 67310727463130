@use '@carbon/react' as *;

.blue-1 {
  background-color: #3bafbf;
}

.blue-3 {
  background-color: #327ec7;
}

.header-2 {
  text-align: center;
  word-wrap: normal;
  color: $text-on-color;
  font-size: 3rem;
  font-weight: 700;
}

.icon-label {
  display: flex;
  flex-direction: row;
  text-wrap: wrap;
  align-items: center;
  column-gap: 0.5rem;
  max-width: 100%;

  &.sm {
    svg {
      width: 0.5rem;
    }
  }

  &.md {
    svg {
      width: 1rem;
    }
  }
}

.paragraph {
  line-height: 2.625em;
  font-size: 1.25rem;
}

.section {
  padding: 2rem;
  background-color: $background;

  .text-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    white-space: pre-line;
  }
}

.split-list {
  padding: 4rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 2rem;
  justify-content: center;
  column-gap: 4rem;

  .list-column {
    display: flex;
    flex-direction: column;
    align-items: center;

    .list-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      row-gap: 2rem;
      padding: 2rem;
      max-width: 100%;
      font-weight: 700;
    }
  }
}

.text-bold-blue-2 {
  color: #3697c3;
  font-weight: 700;
}

@media only screen and (max-width: 800px) {
  .section {
    padding: 0.5rem;

    .text-section {
      padding: 1rem 0.5rem;
    }
  }

  .split-list {
    padding: 0 2rem;
  }

  .header-2 {
    font-size: 2rem;
  }

  .paragraph {
    line-height: 2.25em;
    font-size: 1.125rem;
  }
}
