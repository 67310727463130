@use '@carbon/react' as *;

.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 6rem 4rem 4rem 4rem;
  color: $text-placeholder;

  .header {
    padding-bottom: 1rem;
    font-size: 3rem;
    font-weight: 700;
  }

  .subheader {
    font-size: 1.75rem;
    font-weight: 700;
  }

  .image-container {
    position: relative;
    width: 40%;

    .storm {
      position: absolute;
      width: 30%;
      right: 0;
      top: 0;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .page-not-found {
    padding: 4rem 2rem;
    text-align: center;

    .image-container {
      width: 100%;
    }

    .header {
      font-size: 2rem;
    }

    .subheader {
      font-size: 1.25rem;
    }
  }
}
