@use '@carbon/react' as *;

.menu {
  display: flex;
  flex-direction: column;

  .menu-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;

    .label {
      flex: 1;
    }

    &:hover {
      background-color: $layer-03;
    }

    &.selected {
      border-left: 0.25rem solid $background-brand;
    }
  }

  .dropdown {
    display: flex;
    flex-direction: column;

    .menu-item {
      padding: 0.5rem 1rem 0.5rem 1.5rem;

      &:hover {
        background-color: $layer-03;
      }
    }

    .selected {
      border-left: 0.25rem solid $background-brand;
      background-color: $layer-02;
    }
  }

  .divider {
    height: 0.0625rem;
    margin: 0 1rem;
    background-color: $layer-03;
  }
}
