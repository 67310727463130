@use '@carbon/react' as *;

.footer {
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem 0 4rem;
  color: $text-inverse;
  background-color: $background;
  color: $text-on-color;

  .brand {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    column-gap: 2rem;
    flex: 1;

    svg {
      max-width: 30%;
      fill: $text-on-color;
      stroke: $text-on-color;
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    width: fit-content;
    flex: 1;

    .header {
      font-size: 1.5rem;
      font-weight: 400;
    }

    .label-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      .icon-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 0.5rem;
      }
    }

    .btn-container {
      flex-grow: 1;
      margin-top: 2rem;
    }

    .image-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .image {
        max-width: 50%;

        img {
          width: 100%;
        }
      }
    }

    .text {
      line-height: 1.5rem;
      padding-bottom: 2rem;
    }
  }

  .divider {
    margin: 2rem 0 1rem 0;
    height: 0.125rem;
    background-color: $text-on-color;
  }

  .clickable {
    cursor: pointer;

    &:hover {
      color: #327ec7;
      fill: #327ec7;
      stroke: #327ec7;
    }
  }

  .fineprint {
    display: flex;
    flex-direction: row;

    .social {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      flex: 1;

      svg {
        width: 2rem;
        cursor: pointer;

        &:hover {
          border-radius: 0.4rem;
          border: 2px solid white;
        }
      }
    }

    .brand {
      flex: 2;

      svg {
        height: auto;
        width: 2rem;
      }
    }

    .copy {
      display: flex;
      justify-content: end;
      flex: 1;
    }
  }
}

@media only screen and (max-width: 800px) {
  .footer {
    padding-bottom: 1rem;

    .brand {
      svg {
        max-width: 80%;
      }
    }

    .info, .fine {
      flex-direction: column;
      align-items: center;
    }

    .info {
      row-gap: 4rem;
    }

    .fine {
      row-gap: 1rem;

      .brand {
        width: 100%;
        padding-bottom: 0.25rem;

        svg {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }
  }
}
