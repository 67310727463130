@use '@carbon/react' as *;

.emergency-management {
  height: 100%;
  width: 100%;
  background-image: url('../../../public/images/fire-with-couple.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow-y: scroll;

  .logo-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem;

    svg {
      width: 40%;
      fill: $text-on-color;
    }
  }

  .section {
    padding: 2rem;
    background-color: $background;

    .text-section {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      padding: 2rem 2rem 0 2rem;
      white-space: pre-line;
    }

    .text-section:last-of-type {
      padding-bottom: 5rem;
    }

    &.no-background {
      background-color: transparent;
    }

    &.flex {
      display: flex;
      flex-direction: column;
      row-gap: 3rem;
      padding-bottom: 5rem;
    }

    &.light {
      background-color: $background-inverse;
      color: $text-inverse;
    }

    .list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 4rem;
      row-gap: 2rem;
      flex-wrap: wrap;

      .list-column {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
      }

      .list-item {
        display: flex;
        align-items: center;
      
        svg {
          margin-right: 0.5rem;
          width: 0.5rem;
        }
      }
    }
  }

  .blocks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    min-height: 12rem;
    text-align: center;
    font-size: 1.375rem;
    font-weight: 700;

    .block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      flex: 1;

      .block-fill {
        padding-bottom: 1rem;
        flex: 1;
      }

      .btn {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 600;
        border: 0.125rem solid $text-on-color;
        cursor: pointer;

        &:active {
          opacity: 0.25;
        }

        &:hover {
          color: #000;
          background-color: $text-on-color;
          mix-blend-mode: screen;
        }
      }
    }
  }
}

.image-overlay {
  margin: 1rem;
  padding: 1rem;
  background-color: rgba(black, 0.5);
  max-width: max-content;
}

.divider {
  margin: 1rem 25%;
  height: 0.125rem;
  background-color: $text-on-color;
}

.flood {
  background-image: url('../../../public/images/flood-aerial.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.consult {
  background-image: url('../../../public/images/agency-consultation.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.header, .header-2, .header-2_5, .header-3, .header-4 {
  text-align: center;
  word-wrap: normal;
}

.header {
  margin: 0 5rem 2rem 5rem;
  font-size: 5rem;
  font-weight: 700;
}

.header-2 {
  font-size: 3rem;
  font-weight: 500;
}

.header-2_5 {
  color: $text-on-color;
  font-size: 3rem;
  font-weight: 700;
}

.header-3 {
  font-size: 1.75rem;
  line-height: 3.875rem;
}

.header-4 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.2;
}

.paragraph {
  line-height: 2.625em;
  font-size: 1.25rem;
}

.paragraph-2 {
  line-height: 2.25em;
  font-size: 1.125rem;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}

.background-gray {
  background-color: $layer-03 !important;
}

.blue-1 {
  background-color: #3bafbf;
}

.blue-2 {
  background-color: #3697c3;
}

.blue-3 {
  background-color: #327ec7;
}

.text-bold-blue {
  color: #327ec7;
  font-weight: 700;
}

.text-bold-blue-2 {
  color: #3697c3;
  font-weight: 700;
}

.text-gray {
  color: #474448;
}

@media only screen and not (max-width: 1400px) {
  .emergency-management {
    .section {
      padding: 2rem 10% 2rem 10%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .emergency-management {
    .section {
      padding: 0.5rem 0;

      .text-section {
        padding: 2rem 0.5rem 0 0.5rem;
      }
    }
  }

  .header {
    font-size: 2.5rem;
    margin: 0 0 5rem 0;
  }

  .header-2,
  .header-2_5,
  .header-3,
  .header-4 {
    padding: 1rem;
  }

  .header-2 {
    font-size: 2rem;
  }

  .header-2_5 {
    font-size: 2rem;
  }

  .header-3 {
    font-size: 1.25rem;
    line-height: 3rem;
  }

  .header-4 {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 2rem;
  }

  .logo-header {
    svg {
      min-width: 80%;
    }
  }

  .section {
    .text-section {
      padding: 2rem 0 0 0;
    }

    .list {
      flex-direction: column;
      padding: 0 20%;
    }
  }

  .blocks {
    flex-wrap: wrap;
  }
}
