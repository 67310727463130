.container {
  display: flex;
  flex-direction: row;
}

.image-container {
  width: 33%;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.blurb-container {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  row-gap: 1rem;
  flex: 1;

  &.left {
    padding-left: 0;
    padding-right: 2rem;
  }
}

.blurb-header {
  font-size: 3rem;
  font-weight: 700;
}

.blurb-subheader {
  font-size: 1.25rem;
  font-weight: 600;
}

.blurb {
  font-size: 1.0625rem;
  line-height: 2.375em;
  font-weight: 500;
}

.blurb-addon {
  padding-top: 4rem;

  .blurb-bold {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .list-container {
    padding-top: 1rem;

    .list-column {
      padding-left: 2rem;
    }
  }
}

.icon-label {
  display: flex;
  flex-direction: row;
  text-wrap: wrap;
  align-items: center;
  column-gap: 0.5rem;
  max-width: 100%;

  svg {
    width: 0.5rem;
  }
}

.left-align {
  margin-left: 0;
  margin-right: auto;
}

.right-align {
  margin-right: 0;
  margin-left: auto;
}

@media only screen and (max-width: 800px) {
  .container {
    flex-direction: column;
  }

  .image-container {
    width: 100%;
    padding: 0 2rem;
  }

  .blurb-container {
    flex-direction: column;
    padding: 1rem 0.5rem;

    &.left {
      padding: 1rem 0.5rem;
    }

    &.right {
      padding-left: 4rem;
    }
  }

  .blurb-header {
    padding-top: 2rem;
    text-align: center;
    font-size: 2rem;
  }
  
  .blurb-subheader {
    text-align: center;
    padding-top: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .blurb {
    font-size: 1.0625rem;
    line-height: 2.25em;
    font-weight: 500;
  }

  h1 {
    font-size: 1.5rem;
  }
}
