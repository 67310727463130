@use '@carbon/react' as *;

.form-container {
  display: flex;
  flex-direction: row;

  .close, .close-mobile {
    margin: 2rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: rgba(#FFF, 0.25);
    cursor: pointer;

    :hover {
      border: 0.125rem solid white;
      border-radius: 50%;
    }
  }

  .close-mobile {
    position: absolute;
    top: 0rem;
    left: 0rem;
  }

  .form {
    padding: 2rem 2rem 0.5rem 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    flex: 1;
    background-color: $background;

    .header {
      padding: 2rem;
      text-align: center;
      font-size: 3rem;
      font-weight: 700;
    }

    .input-row {
      display: flex;
      flex-direction: column;
    }

    .input-label {
      font-size: 1rem;
      font-weight: 500;
      padding-bottom: 0.25rem;
    }

    .input-item {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
    }

    .form-controls {
      display: flex;
      padding-bottom: 4rem;
      flex: 1;

      .form-buttons {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-top: auto;
        column-gap: 2rem;
      }
    }

    .info {
      padding: 4rem;
      display: flex;
      row-gap: 4rem;
      flex-direction: column;

      .label-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 2rem;

        .icon-label {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 0.5rem;
        }
      }

      .social {
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 1rem;
        flex: 1;
      
        svg {
          width: 2rem;
          cursor: pointer;
      
          &:hover {
            border-radius: 0.4rem;
            border: 2px solid white;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .form-container {
    .form {
      .info {
        align-items: center;

        .label-container {
          flex-direction: column;
          row-gap: 1rem;
        }
      }
    }
  }
}

@media only screen and not (max-width: 800px) {
  .close-mobile {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .form-container {
    .close {
      display: none;
    }
  }
}
