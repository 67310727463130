@use '@carbon/react' as *;

.blog, .services {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  background-color: $background;

  .content {
    flex-grow: 1;
    background-color: #3bafbf;
  }

  .section {
    padding: 2rem;

    .text-section {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      padding: 2rem;
      white-space: pre-line;
    }

    &.flex {
      display: flex;
      flex-direction: column;
      row-gap: 3rem;
      padding-top: 2rem;
      padding-bottom: 5rem;
    }
  }
}

.header-wrapper {
  position: relative;

  .date {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.bg-image {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.blue-gradient {
  padding: 2rem 2rem 4rem 2rem;
  background-image: linear-gradient(#327ec7, $background);
}

.break {
  height: 15rem;
}

.header-2,
.header-4 {
  text-align: center;
  word-wrap: normal;
}

.header-2 {
  color: $text-on-color;
  font-size: 3rem;
  font-weight: 700;
}

.header-4 {
  font-size: 1.5rem;
  font-weight: 700;
}

.horizontal-gradient {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  background: linear-gradient(-0.25turn, #3bafbf, rgba(255, 255, 255, 0));
  background-color: $background;
}

.horizontal-gradient-reverse {
  height: 2.5rem;
  background: linear-gradient(0.25turn, #3bafbf, rgba(255, 255, 255, 0));
  background-color: $background;
}

.icon-label {
  display: flex;
  flex-direction: row;
  text-wrap: wrap;
  align-items: center;
  column-gap: 0.5rem;
  max-width: 100%;

  svg {
    width: 1rem;
  }
}

.overlay-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 2rem;
  font-weight: 700;

  .list-item {
    flex: 40%
  }
}

.padding-10p {
  padding: 10% !important;
}

.paragraph {
  line-height: 2.625em;
  font-size: 1.25rem;
}

.wrapper {
  padding-bottom: 2rem;
  padding-top: 2rem;
  background-color: $background;
}

.center-wrapper-2 {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  justify-content: center;
  margin: auto;
}

.text-bold-blue {
  color: #327ec7;
  font-weight: 700;
}

@media only screen and not (max-width: 1400px) {
  .services {
    .section {
      padding: 2rem 10% 2rem 10%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .background {
    background-size: auto 100%;
    background-position: center;
    background-attachment: fixed;
  }

  .overlay-list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 800px) {
  .services, .blog {
    .section {
      padding: 1rem;

      &.flex {
        display: flex;
        flex-direction: column;
        row-gap: 3rem;
        padding: 2rem 0.5rem 5rem 0.5rem;
      }

      .text-section {
        padding: 0;
      }
    }
  }

  .split-list {
    flex-direction: column;
    row-gap: 4rem;
    align-items: center;

    .list-column {
      min-width: none;
      max-width: none;
      width: 100%;
    }
  }

  .header {
    font-size: 1rem;
  }

  .header-2 {
    font-size: 2rem;
  }

  .header-4 {
    font-size: 1.25rem;
  }

  .paragraph {
    line-height: 2.25em;
    font-size: 1.125rem;
  }
}
