@use '@carbon/react' as *;

.technology {
  height: 100%;
  width: 100%;
  background-image: url('../../../public/images/fire-with-couple.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow-y: scroll;

  .logo-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem;

    svg {
      width: 40%;
      fill: $text-on-color;
    }
  }

  .list-section {
    display: flex;
    justify-content: center;
    padding: 4rem 0;

    ul {
      width: 80%;
    }

    li {
      list-style: disc;
      list-style-position: outside;
      padding-bottom: 2rem;
    }

    li:last-child {
      padding-bottom: 0;
    }
  }

  .section {
    padding: 2rem;
    background-color: $background;

    .text-section {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      padding: 2rem 2rem 0 2rem;
      white-space: pre-line;
    }

    .text-section:last-of-type {
      padding-bottom: 5rem;
    }

    .list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 4rem;
      row-gap: 2rem;
      padding: 4rem 0;
      flex-wrap: wrap;

      .list-column {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        width: 80%;
      }

      .list-item {
        display: flex;
        align-items: center;
      
        svg {
          margin-right: 0.5rem;
          width: 2rem;
        }
      }
    }
  }

  .blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    min-height: 12rem;

    .block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      min-width: 33%;
      flex: 1;

      .block-header {
        font-size: 1.375rem;
        font-weight: 700;
        text-align: center;
      }

      .block-fill {
        padding-top: 1rem;
        flex: 1;
      }
    }
  }
}

.divider {
  margin: 1rem 25%;
  height: 0.125rem;
  background-color: $text-on-color;
}

.flood {
  background-image: url('../../../public/images/flood-aerial.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  padding: 2rem;
}

.background-gray {
  background-color: $layer-03 !important;
}

.header,
.header-2,
.header-2_5,
.header-3,
.header-4 {
  text-align: center;
  word-wrap: normal;
}

.header {
  margin: 0 5rem 2rem 5rem;
  font-size: 5rem;
  font-weight: 700;
}

.header-2 {
  font-size: 3rem;
  font-weight: 500;
}

.header-2_5 {
  color: $text-on-color;
  font-size: 3rem;
  font-weight: 700;
}

.header-3 {
  font-size: 1.75rem;
  line-height: 3.875rem;
}

.header-4 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.2;
}

.header-section {
  padding-bottom: 4rem;
}

.label {
  font-weight: 600;
}

.paragraph {
  line-height: 2.625em;
  font-size: 1.25rem;
}

.padded {
  padding: 4rem;
}

.blue-gradient {
  padding: 2rem 2rem 4rem 2rem;
  background-image: linear-gradient(#327ec7, $background);
}

.blue-1 {
  background-color: #3bafbf;
}

.blue-2 {
  background-color: #3697c3;
}

.blue-3 {
  background-color: #327ec7;
}

.blue-4 {
  background-color: #2170E3;
}

.blue-5 {
  background-color: #0353e9;
}

.text-bold-blue {
  color: #327ec7;
  font-weight: 700;
}

.text-bold-blue-2 {
  color: #3697c3;
  font-weight: 700;
}

.text-gray {
  color: #474448;
}

@media only screen and not (max-width: 1400px) {
  .technology {
    .section, .flood {
      padding: 2rem 10%;
    }
  }

  .padded {
    padding: 4rem 10%;
  }
}

@media only screen and (max-width: 800px) {
  .technology {
    .section {
      padding: 0.5rem 0;

      .text-section {
        padding: 2rem 0.5rem 0 0.5rem;
      }
    }

    .blocks {
      flex-wrap: wrap;

      .block {
        min-width: 100%;
      }
    }

    .list-section {
      padding: 2rem 0;
    }
  }

  .flood {
    padding: 1rem 0.5rem 2rem 0.5rem;
  }

  .header-section {
    padding-bottom: 2rem;
  }

  .header {
    font-size: 2.5rem;
    margin: 0 0 5rem 0;
  }

  .header-2,
  .header-2_5,
  .header-3,
  .header-4 {
    padding: 1rem;
  }

  .header-2 {
    font-size: 2rem;
  }

  .header-2_5 {
    font-size: 2rem;
  }

  .header-3 {
    font-size: 1.25rem;
    line-height: 3rem;
  }

  .header-4 {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 2rem;
  }

  .logo-header {
    svg {
      min-width: 80%;
    }
  }

  .padded {
    padding: 4rem 0.5rem;
  }

  .paragraph {
    line-height: 2.25em;
    font-size: 1.125rem;
  }
}
