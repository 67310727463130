@use '@carbon/react' as *;

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .content {
    height: 100%;
  }

  .navigation {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
  }
}

a, u {
  text-decoration: none;
  color: $text-primary;
}
