@use '@carbon/styles/scss/theme' as *;

.header-container {
  display: flex;
  flex-direction: row;
  background-color: $layer-01;
  height: 2rem;
  width: 100%;
  padding: 0 1rem;
}

.menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;

    .divider {
      margin: 0 0.5rem;
      height: 1rem;
      border-right: 1px solid $layer-03;
    }

    svg {
      height: 1rem;
      fill: $text-on-color;
    }

    a:hover {
      svg {
        fill: #3697c3;;
      }
    }
  }

  .title {
    padding-right: 0.5rem;

    &:hover {
      a {
        color: #3697c3;
      }
    }
  }
}

.links-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;

  .link-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    cursor: pointer;

    &:hover {
      background-color: $background-hover;
    }
  }
}

.link-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 15rem;
  background-color: $layer-01;

  .menu-item {
    padding: 1rem;

    &:hover {
      background-color: $layer-03;
    }
  }
}

.actions-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;

  .action-dropdown, .action-dropdown-left {
    position: absolute;
    top: 100%;
    cursor: pointer;
  
    &:hover {
      background-color: $background-hover;
    }
  
    .link-menu {
  
      .menu-item {
        padding: 0.5rem;

        svg {
          height: 100%;
          width: 100%;
        }
      }
  
      svg {
        fill: $text-on-color;
        stroke: $text-on-color;
      }
    }
  }

  .action-dropdown {
    right: 0;

    .link-menu {
      min-width: 3rem;
      max-width: 5rem;
    }
  }

  .action-dropdown-left {
    left: 0;

    .link-menu {
      width: calc(1.5 * max-content);
      padding-bottom: 0.5rem;

      .menu-item {
        padding: 1rem;

        .menu-row {
          display: flex;
          flex-direction: row;
          align-items: center;

          .menu-label {
            flex: 1;
          }
        }
      }
    }
  }
}

.menu-divider {
  height: 0.0625rem;
  margin: 0 1rem;
  background-color: $layer-03;
}

.menu-divider:last-child {
  display: none;
}

.clickable {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    background-color: $background-hover;
  }
}

.clickable-action {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  height: 100%;
  cursor: pointer;
  
  &:hover {
    background-color: $background-hover;
  }
}

.floating {
  position: fixed;
  bottom: 3rem;
  right: 3rem;

  .action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    width: 3.5rem;

    .floating-action {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#3bafbf, 0.85);
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      cursor: pointer;

      svg {
        height: 2rem;
        width: 2rem;
      }
    }
    
    .floating-action:hover {
      background-color: rgba(#FFF, 0.85);
      height: 3.5rem;
      width: 3.5rem;
      animation: expand 0.5s infinite linear;
      color: #3bafbf;
    }
  }
}

.mobile-only {
  display: none;
}

.selected {
  border-bottom: 0.25rem solid $background-brand;
}

.menu-item {
  &.selected {
    border-bottom: none;
    border-left: 0.25rem solid $background-brand;
    background-color: $layer-02;
  }
}


@keyframes expand {
  from {
    height: 3rem;
    width: 3rem;
  }

  to {
    height: 3.5rem;
    width: 3.5rem;
  }
}

@media only screen and (max-width: 1000px) {
  .links-container {
    display: none;
  }

  .actions-container:nth-of-type(3) {
    flex-grow: 1;
    justify-content: end;
  }
}

@media only screen and (max-width: 800px) {
  .header-container {
    display: flex;
    flex-direction: row;
    background-color: $layer-01;
    height: 2rem;
    width: 100%;
    padding: 0;
  }

  .menu-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;

    .title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 0.5rem;

      .divider {
        margin: 0 0.5rem;
        height: 1rem;
        border-right: 1px solid $layer-03;
      }

      svg {
        height: 1rem;
        fill: $text-on-color;
      }
    }

    .title-container img {
      height: 1rem;
    }

    .title-container img {
      cursor: pointer;
    }

    .title {
      display: none;
    }
  }

  .links-container {
    display: none;
  }

  .link-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 15rem;
    background-color: $layer-01;

    .menu-item {
      padding: 1rem;

      &:hover {
        background-color: $layer-03;
      }
    }
  }

  .actions-container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;

    .action-dropdown, .action-dropdown-left {
      position: absolute;
      top: 100%;
      cursor: pointer;
    
      &:hover {
        background-color: $background-hover;
      }
    
      .link-menu {
    
        .menu-item {
          padding: 0.5rem;
        }
    
        svg {
          fill: $text-on-color;
          stroke: $text-on-color;
        }
      }
    }

    .action-dropdown {
      right: 0;

      .link-menu {
        min-width: 3rem;
        max-width: 5rem;
      }
    }

    .action-dropdown-left {
      left: 0;

      .link-menu {
        width: calc(1.5 * max-content);
        padding-bottom: 0.5rem;

        .menu-item {
          padding: 1rem;

          .menu-row {
            display: flex;
            flex-direction: row;
            align-items: center;

            .menu-label {
              flex: 1;
            }
          }
        }

        .pad-1 {
          padding: 0 1rem;
        }
      }
    }
  }

  .floating {
    display: none;
  }

  .menu-divider {
    height: 0.0625rem;
    margin: 0 10%;
    background-color: $layer-03;
  }

  .menu-divider:last-child {
    display: none;
  }

  .clickable {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &:hover {
      background-color: $background-hover;
    }
  }

  .clickable-action {
    display: flex;
      align-items: center;
      padding: 0 0.5rem;
      height: 100%;
      cursor: pointer;
    
      &:hover {
        background-color: $background-hover;
      }
  }

  .selected {
    border-bottom: 0.125rem solid $background-brand;
  }
}
