@use '@carbon/react' as *;

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  animation-play-state: paused;

  &.blue {
    background-color: #005b96;
    border: 0.25rem solid #005b96;
  }

  &.gray {
    background-color: $layer-active-02;
    border: 0.25rem solid $layer-active-02;
  }

  &:hover {
    border: 0.25rem solid white;

    .label {
      text-decoration: underline;
    }
  }

  .divider {
    height: 0.0625rem;
    background-color: #b3cde0;
    width: 90%;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    margin: 2rem;
    flex: 3;
  }

  .image-container img {
    max-height: 100%;
    max-width: 100%;
  }

  .image-container svg {
    height: 10rem;
    width: 10rem;
  }

  .label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    flex: 1;
  }
}
