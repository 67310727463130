@use '@carbon/react' as *;

.background {
  background-size: 100% auto;
  background-position: center center;
}

.center-wrapper {
  display: flex;
  justify-content: center;
}

.header, .header-2 {
  text-align: center;
  word-wrap: normal;
}

.header {
  margin-bottom: 2rem;
  font-size: 4rem;
  font-weight: 700;
}

.header-2 {
  color: $text-on-color;
  font-size: 3rem;
  font-weight: 700;
}

.image-overlay {
  padding: 1rem;
  background-color: rgba(black, 0.5);
  max-width: max-content;
}

.margin-10p {
  margin: 10%;
}

.text-bold-blue {
  color: #3697c3;
  font-weight: 700;
}

@media only screen and (max-width: 900px) {
  .background {
    background-size: auto 100%;
    background-position: center;
    background-attachment: fixed;
  }

  .header {
    font-size: 2.5rem;
  }

  .header-2 {
    font-size: 2rem;
  }
}
