.divider {
margin: 2rem 0;
--awb-height: 1.25rem;
  --awb-amount: 1.25rem;
  --awb-sep-color: #327ec7;
  background: radial-gradient(ellipse at 50% -50%, #327ec7 0px, rgba(255, 255, 255, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  background: -webkit-radial-gradient(ellipse at 50% -50%, #327ec7 0px, rgba(255, 255, 255, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  background: -moz-radial-gradient(ellipse at 50% -50%, #327ec7 0px, rgba(255, 255, 255, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  background: -o-radial-gradient(ellipse at 50% -50%, #327ec7 0px, rgba(255, 255, 255, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);

  &.reversed {
    background: radial-gradient(ellipse at 50% 150%, #327ec7 0px, rgba(255, 255, 255, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
    background: -webkit-radial-gradient(ellipse at 50% 150%, #327ec7 0px, rgba(255, 255, 255, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
    background: -moz-radial-gradient(ellipse at 50% 150%, #327ec7 0px, rgba(255, 255, 255, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
    background: -o-radial-gradient(ellipse at 50% 150%, #327ec7 0px, rgba(255, 255, 255, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  }
}

.sm {
  height: 0.25rem;
}

.md {
  height: 0.5rem;
}

.lg {
  height: 1rem;
}
