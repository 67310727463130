.post {
  display: flex;
  flex-direction: column;
  height: 100%;

  .post-container {
    flex-grow: 1;
  }

  .content {
    margin-top: 2rem;
    flex-grow: 1;
  }
}

.four-oh-four {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
