.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 120%;
}

@media only screen and (max-width: 900px) {
  .container {
    background-size: auto 100%;
  }
}
