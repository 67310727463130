@use '@carbon/react' as *;

.blog {
  background-color: $background;

  .section {
    display: flex;
    flex-direction: column;
    padding: 4rem;

    .blog-grid {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;

      .main-blog {
        flex-basis: 50%;
      }

      .blog-quad {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        flex-basis: 50%;
      }

      .blog-duo {
        display: flex;
        flex-direction: row;
        column-gap: 2rem;

        .duo-card-container {
          display: flex;
          flex-direction: column;
          height: 100%;

          .duo-card {
            flex-grow: 1;
          }
        }
      }
    }
  }

  .carousel {
    background-color: $background-inverse;

    .carousel-item {
      margin: 4rem 0;
      height: 100%;
      max-width: 30rem;
    }
  }
}

.post {
  position: relative;
  display: flex;
  flex-direction: row;

  .actions {
    position: fixed;
    top: 1.5rem;
    left: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin: 1rem;
    
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
      cursor: pointer;
      background-color: rgba(#000, 0.5);
      border-radius: 50%;
      margin: 0.5rem;


      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    
      &:hover {
        width: 3rem;
        height: 3rem;
        background-color: rgba(#000, 0.7);
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .blog {
    .section {
      padding: 4rem 0;

      .blog-grid {
        flex-direction: column;
        row-gap: 2rem;

        .main-blog {
          padding: 0 4rem 2rem 4rem ;
        }

        .blog-quad {
          padding: 4rem;
          background-color: $background-inverse;
        }
      }
    }

    .carousel {
      background-color: $background;

      .carousel-item {
        margin: 2rem 1rem 4rem 1rem;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .blog {
    .section {
      padding: 2rem 1rem 0 1rem;

      .blog-grid {
        .main-blog {
          padding: 0 1rem;
        }

        .blog-quad {
          row-gap: 2rem;
          padding: 2rem 2rem;
        }

        .blog-duo {
          flex-direction: column;
          row-gap: 2rem;
        }
      }
    }
  }

  .post {
    .actions {
      top: 2rem;
      right: 0;

      a {
        display: none;
      }
    }
  }
}
