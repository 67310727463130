@use '@carbon/react' as *;

.blog-card {
  display: flex;
  flex-direction: column;

  .image {
    img {
      width: 100%;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    row-gap: 0.5rem;
    flex-grow: 1;

    .header {
      padding: 1rem;
      word-wrap: normal;
      color: #327ec7;
      font-size: 2rem;
      font-weight: 700;

      &.minimized {
        padding: 0.5rem;
      }
    }

    .snippet {
      padding: 0 1rem;
      flex-grow: 1;

      &.minimized {
        font-size: 0.85rem;
      }
    }

    .tags {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
      height: 1.5rem;
      margin-top: auto;

      &.minimized {
        margin: 0;
        height: 1rem;
      }
    }

    .action {
      margin: 1rem 1rem 1rem auto;

      &.minimized {
        margin: 0.5rem 0.5rem 0.5rem auto;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .blog-card {
    .info {
      .footer {
        flex-direction: column;
        row-gap: 2rem;

        .tags {
          justify-content: center;
        }

        .action {
          padding: 0;
          margin-right: 1rem;
          margin-left: auto;
        }
      }

      .header {
        font-size: 1.5rem;
      }
    }
  }
}
