@use '@carbon/react' as *;

.bg-image {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.header-2,
.header-4 {
  text-align: center;
  word-wrap: normal;
}

.header-2 {
  color: $text-on-color;
  font-size: 3rem;
  font-weight: 700;
}

.header-4 {
  font-size: 1.5rem;
  font-weight: 700;
}

.image-overlay {
  padding: 1rem;
  background-color: rgba(black, 0.5);
  max-width: max-content;
}

.padding-10p {
  padding: 10% !important;
}

.paragraph {
  line-height: 2.625em;
  font-size: 1.25rem;
}

.section {
  padding: 2rem;
  background-color: $background;

  .text-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    white-space: pre-line;
  }
}

@media only screen and (max-width: 800px) {
  .header-2 {
    font-size: 2rem;
  }

  .header-4 {
    font-size: 1.25rem;
  }

  .paragraph {
    padding-top: 1rem;
    line-height: 2.25em;
    font-size: 1rem;
  }

  .padding-10p {
    padding: 1rem !important;
  }
}
