@use '@carbon/react' as *;

.arrow {
  position: absolute;
  z-index: 2;
  top: calc(50% - 1rem);
  cursor: pointer;
  color: #3bafbf;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
}

.indicator {
  padding: 0 0.5rem;
  color: #3bafbf;
  cursor: pointer;

  &:hover {
    border-bottom: 0.125rem solid #3bafbf;
  }
}
