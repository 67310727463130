.divider-container {
  display: flex;
  flex-direction: column;
}

.triangle-down, .triangle-up {
  width: 0;
  height: 0;
  border-left: 1.5rem solid transparent;
  border-right: 1.5rem solid transparent;
  margin-left: auto;
  margin-right: auto;
}

.divider {
  height: 0.25rem;
}
