.black-fill {
  fill: rgb(0, 0, 0);
}

.white-fill {
  fill: rgb(255, 255, 255);
}

.black-stroke-no-fill {
  stroke: rgb(0, 0, 0);
  fill: none;
}

.white-stroke-no-fill {
  stroke: rgb(255, 255, 255);
  fill: none;
}
