@use '@carbon/react' as *;

.bold {
  font-weight: 700;
}

.header-2 {
  text-align: center;
  word-wrap: normal;
  color: $text-on-color;
  font-size: 3rem;
  font-weight: 700;
}

.italicized {
  padding: 2rem 2rem 0 2rem;
  font-style: italic;
}

.paragraph {
  line-height: 2.625em;
  font-size: 1.25rem;
}

.section {
  padding: 2rem;
  background-color: $background;

  .text-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    white-space: pre-line;
  }
}

@media only screen and (max-width: 800px) {
  .section {
    .text-section {
      padding: 0;
    }
  }
  .header-2 {
    font-size: 2rem;
  }

  .paragraph {
    padding: 0;
    line-height: 2.25em;
    font-size: 1.125rem;
  }
}
