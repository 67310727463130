@use '@carbon/react' as *;

.blue-gradient {
  padding: 2rem 2rem 4rem 2rem;
  background-image: linear-gradient(#327ec7, $background);
}

.header-2 {
  text-align: center;
  word-wrap: normal;
  color: $text-on-color;
  font-size: 3rem;
  font-weight: 700;
}

.paragraph {
  line-height: 2.625em;
  font-size: 1.25rem;
}

.section {
  padding: 2rem;
  background-color: $background;

  .text-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    white-space: pre-line;
  }
}

@media only screen and (max-width: 800px) {
  .section {
    padding: 0.5rem 0;
  
    .text-section {
      padding: 2rem 0.5rem;
    }
  }

  .header-2 {
    font-size: 2rem;
  }

  .paragraph {
    line-height: 2.25em;
    font-size: 1.125rem;
  }
}
